import Icon from "@atoms/icon"
import { ICourseActivitySlide } from "./_courseSlide.interface"
import Column from "@atoms/column"
import IconWithLabel from "@molecules/iconWithLabel"
import CHARLIMIT from "@helpers/constants/charLimit"
import { trimText } from "@utils/stringparsing"

const CourseActivitySlide = (props: ICourseActivitySlide) => {
  const setSlide = () => {
    props?.handleClickActivity(props?.id)
  }

  return (
    <Column desktop={3} tablet={6} mobile={12}>
      <>
        <div
          className={`course-activity-slide-container ${props?.className}`}
          onClick={setSlide}
          id={props?.id}>
          <div className={`slide-content-wrapper ${props?.isActive ? "active" : ""}`}>
            <div className="slide-content ">
              <div className="additional-info">
                <div className="info-subtitle">{trimText(props?.subTitle ?? "", CHARLIMIT.SUBTITLE)}</div>
                {props?.duration ? (
                  <div className="time-info">
                    <Icon iconName={"TimeIcon"} height={24} width={24} />
                    {props?.duration}
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <div className="text-block">{props?.title}</div>
            </div>
          </div>
          {props?.enableOverlay && props?.activityStatus && (
            <div className={`course-activity-slide-container-wrapper ${props?.isActive ? "active" : ""}`}>
              <div
                className={`course-activity-slide-container-overlay ${props?.isActive ? "active" : ""}`}
                onClick={setSlide}
                id={props?.id}>
                <IconWithLabel icon={<Icon iconName="CheckMarkIconFilled" />}>
                  {props?.overlayLabel}
                </IconWithLabel>
              </div>
            </div>
          )}
        </div>
      </>
    </Column>
  )
}

export default CourseActivitySlide
