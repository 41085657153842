import { AnchorButton, Button, Icon, RichText } from "@atoms"
import { IActivityInfo } from "./_activityInformation.interface"
import { postApi } from "@utils/baseApi"
import { useEffect, useState } from "react"

const ActivityInformation = ({
  durationLabel,
  duration,
  title,
  keyOpinion,
  desc,
  enableMarkAsComp,
  markAsCompCtaLabel,
  downloadCtaDetails,
  completedLabel,
  activityStatus,
  selectedAid,
  setActivityCompleted,
}: IActivityInfo) => {
  const [ctaLabel, setCtaLabel] = useState<string>(
    !activityStatus ? markAsCompCtaLabel ?? "" : completedLabel ?? "",
  )
  const [isCompleted, setCompleted] = useState<boolean>(activityStatus ?? false)
  const queryString = decodeURI(
    window?.location?.search ? window.location.search?.replace("?", "&") : "",
  )

  const getIdValue = (queryString: string, expression: RegExp) => {
    const match = queryString.match(expression)

    return match?.length ? match?.[1] : null
  }

  const cidValue = getIdValue(queryString, /[?&]cid=([^&]+)/)
  const midValue = getIdValue(queryString, /[?&]mid=([^&]+)/)
  const aidValue = getIdValue(queryString, /[?&]aid=([^&]+)/)
  
  const handleClick = async () => {
    const requestObject = {
      course_id: cidValue,
      module_id: midValue,
      activity_id: selectedAid ? `${selectedAid}` : aidValue,
    }
    const response = await postApi("/solarapi/academy/course/module/activity", requestObject)
    if (response) {
      setActivityCompleted(true)
    } else {
      setActivityCompleted(false)
    }
  }

  useEffect(() => {
    setActivityCompleted(false)
    if (activityStatus) {
      setCtaLabel(completedLabel ?? "")
      setCompleted(true)
    } else {
      setCtaLabel(markAsCompCtaLabel ?? "")
      setCompleted(false)
    }
  }, [activityStatus])

  return (
    <div className="activityInfo">
      {durationLabel && duration ? (
        <p className="activityInfo-duration">{`${durationLabel}: ${duration}`}</p>
      ) : (
        <></>
      )}
      {title ? <h3 className="activityInfo-title">{title}</h3> : <></>}
      {keyOpinion ? <p className="activityInfo-leader">{keyOpinion}</p> : <></>}
      {desc ? <RichText content={desc} className="activityInfo-desc" /> : <></>}
      {ctaLabel && enableMarkAsComp ? (
        <Button
          className="activityInfo-markAsCompleteCta"
          tabindex={0}
          onClick={handleClick}
          isSecondary
          icon={!isCompleted ? <Icon iconName={"CheckIcon"} /> : <></>}
          iconPosition="left"
          isDisabled={isCompleted}>
          {ctaLabel}
        </Button>
      ) : (
        <></>
      )}
      {downloadCtaDetails?.title && downloadCtaDetails?.ctaLabel && downloadCtaDetails?.ctaLink ? (
        <div className="activityInfo-download">
          <h3 className="activityInfo-download-title">{downloadCtaDetails?.title}</h3>
          <AnchorButton
            tabindex={1}
            href={downloadCtaDetails?.ctaLink}
            isExternal={true}
            icon={<Icon iconName="DownloadIcon" />}
            className="activityInfo-download-cta"
            download={true}
            isSecondary>
            {downloadCtaDetails?.ctaLabel}
          </AnchorButton>
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}

export default ActivityInformation
