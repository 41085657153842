import { DATE_FORMATS } from "@helpers/constants/generic"
import { getApi } from "@utils/baseApi"
import { filterNullValues } from "@utils/paywallUtilities"
import moment from "moment"

function getNidValue(url: string) {
  const regex = /[?&]filter\[nid\]=([^&]+)/
  const match = url.match(regex)

  return match ? match[1] : null
}

const getModulesInfoSectionData = async (url: string, nid: number) => {
  const rawData = (await getApi(url.replace(/\?.*/, ""), false)) as any

  const getActivitiesData = (activities: any, link?: string) => {
    return activities?.map((item: any, index: number) => ({
      title: item?.field_activity_title ?? "",
      desktopColumns: 3,
      tabletColumns: 1,
      id: item?.id,
      cardImage: {
        src: item?.field_activity_image?.[0]?.src ?? "",
        alt: item?.field_activity_image?.[0]?.alt ?? "",
      },
      difficultyLevel: {
        label: item?.field_date_of_commencement
          ? moment(item?.field_date_of_commencement).format(DATE_FORMATS.MMM_DD_YYYY)
          : "",
        icon: "CalendarIcon1",
      },
      detailsPath: link ?? "",
      courseDuration: {
        label: item?.field_activity_time,
        icon: "TimeIcon",
      },
      taxonomies: item?.field_course_topic_tag?.length && item.field_course_topic_tag.map((item: any) => ({
        taxonomyLabel: item?.name ?? "",
      })) || [],
      dispStatAbove: true,
      enableStatus: item?.enable_disable_course_completion_status == 1,
      completionStatusLabel: item?.activity_status_label ?? "",
      enableOverlay: item?.activity_status == 1 && item?.enable_disable_course_completion_overlay == 1,
      overlayLabel: item?.overlay_label ?? "",
      activityStatus: item?.activity_status == 1,
      completionStatus: item?.activity_status ?? null,
    }))
  }

  const responseData = rawData?.map((item: any) => ({
    modulesInformation: {
      title: item?.title ?? "",
      trainingTime: item?.field_training_time ?? "",
      desc: item?.body ?? "",
      keyOpinion: item?.field_key_opinion_leaders ?? "",
      nid: item?.nid ?? "",
      ctaLabel: item?.view_all_cta_label ?? "",
      ctaLink: item?.view_all_cta_link ?? "",
      moduleHeading: item?.field_module_heading ?? "",
      enableStatus: item?.enable_disable_course_completion_status == 1,
      completionStatusLabel: item?.module_status_label ?? "",
      moduleStatus: item?.module_status == 1,
      completionStatus: item?.module_status ?? null,
    },
    carouselData: {
      duration: 0,
      carouselBlockType: "catalogue",
      itemPerSlide:
        getActivitiesData(item?.activities, item?.view_all_cta_link)?.slice(0, 6)?.length > 1
          ? 2
          : 1,
      slidesInfo:
        filterNullValues(
          getActivitiesData(item?.activities, item?.view_all_cta_link)?.slice(0, 6),
        ) ?? [],
      enableException: true,
      slideItemsForTablet: 1,
      slideItemsForMobile: 1,
    },
  }))

  return {
    responseData: responseData,
    activityHeading: rawData?.[0]?.module_activity_heading ?? "",
    course_id: getNidValue(url),
  }
}

export default getModulesInfoSectionData
