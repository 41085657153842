import React, { useEffect, useMemo, useState } from "react"
import { IFlexibleCarouselProps } from "./_flexibleCarousel.interface"
import { Anchor, CourseActivitySlide, Icon, IconButton, Img, RichText } from "@atoms"
import getDirection from "@utils/direction"
import Slider from "react-slick"
import { ProductCard, RotatingHeroBanner } from "@organisms"
import {
  ArticleTeaserCompositionBlock,
  MenuLinkWithIconGroupBlock,
} from "@templates/flexible/flexibleBlocks"

import { useMediaQuery } from "@helpers/hooks"
import { FlexibleCard } from "@molecules"

enum STYLE_TYPE {
  IMAGE_CAROUSEL_WITH_THUMBNAILS = "image_carousel_with_thumbnails",
  ROTATING_HERO_BANNER = "rotating_hero_banner",
  ARTICLE_TEASER_COMPOSITION_CAROUSEL = "article_teaser_composition_carousel",
  MENU_LINK_WITH_CAROUSEL = "menu_link_carousel",
  SANDOZ_ACADEMY_COURSE_CATALOGUE = "sandoz_academy_course_catalogue",
}

enum CAROUSEL_BLOCK_TYPE {
  HERO_BANNER = "hero_banner",
  ARTICLE_TEASER_COMPOSITION = "article_teaser_composition",
  MENU_LINK_WITH_ICON = "menu_link_with_icon",
  PRODUCT_TEASER_CARDS = "pim_product_teaser",
  CATALOGUE = "catalogue",
  COURSE_ACTIVITY = "course_activity"
}

const FlexibleCarousel = ({
  carouselDisplayType,
  slidesInfo,
  duration,
  itemPerSlide,
  carouselBlockType,
  enableException = false,
  slideItemsForTablet,
  slideItemsForMobile,
  course_id,
  module_id,
  setAid,
  aId,
  isActivityCard = false,
}: IFlexibleCarouselProps) => {
  const itemsPerSlideForTablet = slideItemsForTablet
  ? slideItemsForTablet
  : (itemPerSlide as number)
const itemsPerSlideForMobile  = slideItemsForMobile ? slideItemsForMobile : itemPerSlide as number
const [selectedActivity, setSelectedActivity] = useState<string>(aId ?aId: slidesInfo?.[0]?.id )
  useEffect(() => {
    require("slick-carousel/slick/slick.css")
    require("slick-carousel/slick/slick-theme.css")
  }, [])

  const NextArrow = (nextArrowProps: any) => {
    const { className, style, onClick, className2 } = nextArrowProps
    return (
      <div
        className={`carouselWrapper ${carouselType} ${className} ${className2}`}
        style={{ ...style }}
        onClick={onClick}>
        <div className="slider-container-right-arrow-box" aria-label="next-btn">
          <IconButton
            tabindex={0}
            icon={<Icon iconName="ArrowRightIcon" />}
            ariaLabel="carousel button"
          />
        </div>
      </div>
    )
  }

  const PrevArrow = (prevArrowProps: any) => {
    const { className, style, onClick, className2 } = prevArrowProps
    return (
      <div
        className={`${carouselType} ${className} ${className2}`}
        style={{ ...style }}
        onClick={onClick}>
        <div className="slider-container-left-arrow-box" aria-label="left-btn">
          <IconButton
            tabindex={0}
            icon={<Icon iconName="ArrowLeftIcon" />}
            ariaLabel="carousel button"
          />
        </div>
      </div>
    )
  }

  const getImage = (props: any) => {
    const isMobile = useMediaQuery("(max-width: 767px)")
    const shouldRenderDesktopImage = useMemo(() => {
      return !isMobile || (isMobile && !props?.mobileImage?.src)
    }, [props?.mobileImage, isMobile])

    const imageComp = useMemo(() => {
      const deskStyleImg = props?.desktopImage?.styledimage
      const mobStyleImg = props?.mobileImage?.styledimage
      const imgType = "teaser_block_carousel"
      const src =
        !shouldRenderDesktopImage && mobStyleImg
          ? props?.mobileImage?.src
          : props?.desktopImage?.src
      const alt =
        !shouldRenderDesktopImage && mobStyleImg
          ? props?.mobileImage?.alt
          : props?.desktopImage?.alt
      const { height: desktopHeight, width: desktopWidth } = props?.desktopImage
      const width = !shouldRenderDesktopImage && isMobile ? props?.mobileImage?.width : desktopWidth
      const height =
        !shouldRenderDesktopImage && isMobile ? props?.mobileImage?.height : desktopHeight

      const imgComp = (
        <Img
          type={imgType}
          styledimage={!shouldRenderDesktopImage && mobStyleImg ? mobStyleImg : deskStyleImg}
          src={src ?? null}
          alt={alt ?? ""}
          width={width}
          height={height}
        />
      )
      if (deskStyleImg || mobStyleImg) {
        return imgComp
      }

      return <Img type="default" {...props.desktopImage} />
    }, [props?.desktopImage, props?.mobileImage, shouldRenderDesktopImage, isMobile])

    return imageComp ?? <></>
  }

  const handleClickActivity = (id: string) => {
    setAid(id)
    setSelectedActivity(id)
  }
  const carouselType = carouselDisplayType || undefined
  const styleType = STYLE_TYPE.IMAGE_CAROUSEL_WITH_THUMBNAILS
  const [mainSlider, setMainSlider] = useState<Slider | null>(null)
  const [thumbnailSlider, setThumbnailSlider] = useState<Slider | null>(null)
  const isMobile = useMediaQuery("(max-width: 991px)")

  const prevArrows = (getDirection() === "rtl" ? <NextArrow /> : <PrevArrow />) || undefined
  const nextArrows = (getDirection() === "rtl" ? <PrevArrow /> : <NextArrow />) || undefined

  const slideToShowDesktop =
    slidesInfo?.length >= 2 && slidesInfo?.length <= 8 ? slidesInfo?.length : 8
  const slideToShowMobile =
    slidesInfo?.length >= 2 && slidesInfo?.length <= 4 ? slidesInfo?.length : 4

  const settings = {
    dots: carouselType === styleType ? false : true,
    slidesToShow: carouselType === styleType ? 1 : enableException && isMobile ? 1 : itemPerSlide,
    slidesToScroll: carouselType === styleType ? 1 : enableException && isMobile ? 1 : itemPerSlide,
    arrow: carouselType === styleType ? false : true,
    prevArrow: prevArrows,
    nextArrow: nextArrows,
    swipeToSlide: false,
    accessibility: true,
    infinite: true,
    rtl: getDirection() === "rtl",
    autoplay: duration === 0 || null ? false : true,
    autoplaySpeed: duration,
    adaptiveHeight: true,
    useTransform: false,

    responsive: [
      {
        breakpoint: 2560,
        settings: {
          slidesToShow: carouselType === styleType ? 1 : itemPerSlide,
          slidesToScroll: carouselType === styleType ? 1 : itemPerSlide,
          dots: carouselType === styleType ? false : true,
          arrow: false,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow:
            carouselType === styleType
              ? 1
              : carouselDisplayType === CAROUSEL_BLOCK_TYPE.PRODUCT_TEASER_CARDS
              ? 2
              : itemsPerSlideForTablet,
          slidesToScroll:
            carouselType === styleType
              ? 1
              : carouselDisplayType === CAROUSEL_BLOCK_TYPE.PRODUCT_TEASER_CARDS
              ? 1
              : itemsPerSlideForTablet,
          dots: carouselType === styleType ? false : true,
          arrow: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow:
            carouselType === styleType ||
            carouselDisplayType != CAROUSEL_BLOCK_TYPE.MENU_LINK_WITH_ICON
              ? 1
              : itemsPerSlideForMobile,
          slidesToScroll:
            carouselType === styleType ||
            carouselDisplayType != CAROUSEL_BLOCK_TYPE.MENU_LINK_WITH_ICON
              ? 1
              : itemsPerSlideForMobile,
          dots: carouselType === styleType ? false : true,
          arrow: true,
        },
      },
    ],
  }

  const thumbnailsSettings = {
    dots: false,
    slidesToShow: 8,
    prevArrow: getDirection() === "rtl" ? <NextArrow /> : <PrevArrow />,
    nextArrow: getDirection() === "rtl" ? <PrevArrow /> : <NextArrow />,
    centerMode: false,
    focusOnSelect: true,
    infinite: slidesInfo?.length > 1,
    swipeToSlide: true,
    accessibility: true,
    rtl: getDirection() === "rtl",
    autoplay: duration === 0 || null ? false : true,
    autoplaySpeed: duration,

    responsive: [
      {
        breakpoint: 2560,
        settings: {
          slidesToShow: slideToShowDesktop,
          slidesToScroll: 1,
          dots: false,
          arrow: false,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: slideToShowMobile,
          slidesToScroll: 1,
          dots: false,
          arrow: false,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: slideToShowMobile,
          slidesToScroll: 1,
          dots: false,
          arrow: false,
        },
      },
    ],
  }

  const ImageWithThumbnailView = (slideInfo: any) => {
    return (
      <div className="heroBanner-flexible">
        <div className="flexible-carousel-wrapper">
          <h3 className="flexible-carousel-title">
            {slideInfo?.slideInfo?.heroBannerContent?.title}
          </h3>
          {slideInfo?.slideInfo?.isImageClickable &&
          slideInfo?.slideInfo?.heroBannerContent?.link ? (
            <Anchor
              href={slideInfo?.slideInfo?.heroBannerContent?.link ?? ""}
              tabindex={0}
              noUnderline={true}
              isExternal={slideInfo?.slideInfo?.isExternalLink}>
              {getImage(slideInfo?.slideInfo?.heroBannerImage)}
            </Anchor>
          ) : (
            <>{getImage(slideInfo?.slideInfo?.heroBannerImage)}</>
          )}
          {slideInfo?.slideInfo?.heroBannerContent?.paragraph ? (
            <RichText
              className="flexible-carousel-text"
              content={slideInfo?.slideInfo?.heroBannerContent?.paragraph}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
    )
  }

  const articleTeaserStyleType = carouselType === STYLE_TYPE.ARTICLE_TEASER_COMPOSITION_CAROUSEL
  const articleBlockType = carouselBlockType === CAROUSEL_BLOCK_TYPE.ARTICLE_TEASER_COMPOSITION

  const menuLinkStyleType = carouselType === STYLE_TYPE.MENU_LINK_WITH_CAROUSEL
  const menuLinkBlockType = carouselBlockType === CAROUSEL_BLOCK_TYPE.MENU_LINK_WITH_ICON

  const rotatingHeroStyleType = carouselType === STYLE_TYPE.ROTATING_HERO_BANNER

  const imageWithuThumbnailStyle = carouselType === STYLE_TYPE.IMAGE_CAROUSEL_WITH_THUMBNAILS
  const imageWithuThumbnailBlokType = carouselBlockType === CAROUSEL_BLOCK_TYPE.HERO_BANNER
  const slidesSection = enableException && isMobile ? slidesInfo?.slice(0, 3) : slidesInfo

  return (
    <>
      {rotatingHeroStyleType && <RotatingHeroBanner slidesInfo={slidesInfo} />}
      <div
        className={`flexible-carousel slider-container ${
          carouselType === "image_carousel_with_thumbnails" ? "" : "flexible-carousel-banner"
        } ${carouselType} ${
          enableException && slidesSection?.length == 1 && !isMobile ? "centre-align" : ""
        } ${
          slidesSection?.length < 4 && carouselBlockType == CAROUSEL_BLOCK_TYPE.COURSE_ACTIVITY
            ? "activity-detail"
            : ""
        }`}>
        <div className={`slider-for ${menuLinkBlockType ? "menu-link-with-icon" : ""}`}>
          <Slider {...settings} ref={setMainSlider} asNavFor={thumbnailSlider ?? undefined}>
            {slidesSection?.map((slide: any, index: number) => {
              if (carouselDisplayType === CAROUSEL_BLOCK_TYPE.PRODUCT_TEASER_CARDS) {
                return <ProductCard {...slide?.blockdata} key={index} />
              }
              if (carouselDisplayType === STYLE_TYPE.SANDOZ_ACADEMY_COURSE_CATALOGUE) {
                return <FlexibleCard {...slide} className="flexible-card-carousel" key={index} />
              }
              switch (carouselBlockType) {
                case CAROUSEL_BLOCK_TYPE.HERO_BANNER:
                  if (imageWithuThumbnailStyle && imageWithuThumbnailBlokType) {
                    return <ImageWithThumbnailView slideInfo={slide} />
                  } else {
                    return null
                  }
                case CAROUSEL_BLOCK_TYPE.ARTICLE_TEASER_COMPOSITION:
                  if (articleTeaserStyleType && articleBlockType) {
                    return (
                      <ArticleTeaserCompositionBlock
                        block={slide?.blockdata}
                        key={index}
                        index={index}
                        colBackground={undefined}
                        applicationConfiguration={undefined}
                        handleBookmarks={undefined}
                        internalLayout={undefined}
                      />
                    )
                  } else {
                    return null
                  }
                case CAROUSEL_BLOCK_TYPE.MENU_LINK_WITH_ICON:
                  if (menuLinkStyleType && menuLinkBlockType) {
                    return (
                      <MenuLinkWithIconGroupBlock
                        block={slide?.blockdata}
                        key={index}
                        index={index}
                        colBackground={undefined}
                        applicationConfiguration={undefined}
                        handleBookmarks={undefined}
                        internalLayout={undefined}
                      />
                    )
                  } else {
                    return null
                  }
                case CAROUSEL_BLOCK_TYPE.CATALOGUE:
                  return (
                    <FlexibleCard
                      {...slide}
                      className={
                        enableException && slidesSection?.length == 1 && !isMobile && "width-adjust"
                      }
                      course_id={course_id}
                      module_id={module_id}
                      isActivityCard
                    />
                  )
                case CAROUSEL_BLOCK_TYPE.COURSE_ACTIVITY:
                  return (
                    <CourseActivitySlide
                      {...slide}
                      className={slidesSection?.length < 4 ? "width-adjust" : ""}
                      isActive={selectedActivity == slide?.id}
                      handleClickActivity={handleClickActivity}
                    />
                  )
                default:
                  return null
              }
            })}
          </Slider>
        </div>

        {carouselType === "image_carousel_with_thumbnails" ||
        (!STYLE_TYPE.ARTICLE_TEASER_COMPOSITION_CAROUSEL && !STYLE_TYPE.MENU_LINK_WITH_CAROUSEL) ? (
          <div className="slider-nav">
            <Slider
              {...thumbnailsSettings}
              ref={setThumbnailSlider}
              asNavFor={mainSlider ?? undefined}>
              {slidesInfo?.map((image: any, index: any) => (
                <div key={index}>{getImage(image?.heroBannerImage)}</div>
              ))}
            </Slider>
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  )
}

export default FlexibleCarousel
