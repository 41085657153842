import Loader from "@atoms/loader"
import { getAllFleixbleBlocksData } from "@helpers/flexibleApi"
import useSWRRequest from "@helpers/hooks/useSWRRequest"
import FlexiTabs from "@molecules/flexitabs"
import { useCallback, useEffect, useState } from "react"
import { connect, useDispatch, useSelector } from "react-redux"
import { Dispatch } from "redux"
import {
  btnClickTabChange,
  changeTab,
  moduleId,
  updateTab,
} from "store/actions/AcademyCourseActionCreators"
interface DispatchProps {
  updateTab: (data: any) => void
  changeTab: (data: any) => void
  btnClickTabChange: (data: any) => void
  moduleId: (data: any) => void
}

type StateTypes = {
  academyCourseFilterData: {
    tabSelected: boolean
    tabChanged: boolean
    btnClickTabChanged: boolean
    moduleId: any
  }
}
type tabTypes = {
  tabHeadItems: any
  tabContents: any
}

type Props = DispatchProps & StateTypes & tabTypes
const CourseTabs = (props: Props) => {
  const { tabHeadItems, tabContents, academyCourseFilterData } = props
  const [tabData, setTabData] = useState<any>([])
  const user: any = useSelector((state: UserState) => state.user)
  const dispatch: Dispatch<any> = useDispatch()

  const updateTabState: any = useCallback((value: boolean) => props?.updateTab(value), [dispatch])

  const changeTabState: any = useCallback((value: boolean) => props?.changeTab(value), [dispatch])

  const btnClickTabChangeState: any = useCallback(
    (value: boolean) => props?.btnClickTabChange(value),
    [dispatch],
  )

  const moduleIdState: any = useCallback((value: any) => props?.moduleId(value), [dispatch])

  useEffect(()=>{
    if(user){
      changeTabState({tabSelected:true})
    }
  },[user])

  const fetchData = async (tabContent: any) => {
    try {
      const response = await Promise.all(await getAllFleixbleBlocksData(tabContent?.blocks))
      return {
        ...tabContent,
        blocks: response,
      }
    } catch (error) {
      console.error("content error", error)
      return null
    }
  }

  const fetchTabData = async (tabContents: any) => {
    const updatedTabData = []

    for (let i = 0; i < tabContents.length; i++) {
      const updatedTab = await fetchData(tabContents[i])
      if (updatedTab) {
        updatedTabData.push(updatedTab)
      }
    }

    return updatedTabData
  }

  const { data, isLoading } = useSWRRequest(tabContents, async () => {
    const updatedTabData = await fetchTabData(tabContents)
    setTabData(updatedTabData)
    return updatedTabData
  })

  if (!isLoading) {
    return (
      <FlexiTabs
        key="course-tabs"
        activeTab={undefined}
        tabHeadItems={tabHeadItems}
        tabContents={data}
        isToggleTab={true}
        updateTab={updateTabState}
        changeTab={changeTabState}
        btnClickTabChange={btnClickTabChangeState}
        moduleSectionId={moduleIdState}
      />
    )
  }

  return  (
    <div className="load-container">
      <Loader display={isLoading} />
    </div>)
}

const mapDispatch = {
  updateTab: (data: any) => updateTab(data),
  changeTab: (data: any) => changeTab(data),
  btnClickTabChange: (data: any) => btnClickTabChange(data),
  moduleId: (data: any) => moduleId(data),
}

const mapState = (state: any) => ({
  academyCourseFilterData: state.academyCourseReducer,
})
export default connect(mapState, mapDispatch)(CourseTabs)
